import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ProductCard from "./ProductCard";
import ProductCardSkelton from "../../Skelton/ProductCardSkelton";
import { useSelector } from "react-redux";
import guatFront from "../../../assets/Shop/guatemala_front.png";
import guatBack from "../../../assets/Shop/guatemala_back.png";
import nicaFront from "../../../assets/Shop/nicaragua_front.png";
import nicaBack from "../../../assets/Shop/nicaragua_back.png";
import ethiFront from "../../../assets/Shop/ethiopia_front.png";
import ethiBack from "../../../assets/Shop/ethiopia_back.png";
import coloFront from "../../../assets/Shop/colombia_front.png";
import coloBack from "../../../assets/Shop/colombia_back.png";
import brooFront from "../../../assets/Shop/brooklyn_front.png";
import brooBack from "../../../assets/Shop/brooklyn_back.png";
import cremFront from "../../../assets/Shop/crema_front.png";
import cremBack from "../../../assets/Shop/crema_back.png";
import darkFront from "../../../assets/Shop/dark_roast_front.png";
import darkBack from "../../../assets/Shop/dark_roast_back.png";

const StoreSection = ({ setCurrentId }) => {
  const products = useSelector((state) => state.products);

  const data = [
    {
      id: 1,
      Category: "Single Origin",
      Country: "Guatemala",
      RoastType: "Medium Roast",
      CoffeeTaste: "Sweet & Full Bodied",
      Price: "18",
      ShopifyLink:
        "https://shop.elevacoffee.com/collections/single-origin/products/guatemala?variant=40908888963",
      ProductFront: guatFront,
      ProductBack: guatBack,
      Tag: "single",
    },
    {
      id: 2,
      Category: "Single Origin",
      Country: "Nicaragua",
      RoastType: "Medium Roast",
      CoffeeTaste: "Rich & Chocolatey ",
      Price: "18",
      ShopifyLink:
        "https://shop.elevacoffee.com/collections/single-origin/products/nicaragua?variant=40908931139",
      ProductFront: nicaFront,
      ProductBack: nicaBack,
      Tag: "single",
    },
    {
      id: 3,
      Category: "Single Origin",
      Country: "Ethiopia",
      RoastType: "Medium Light Roast",
      CoffeeTaste: "Fruity & Bold",
      Price: "18",
      ShopifyLink: "https://shop.elevacoffee.com/products/ethiopia",
      ProductFront: ethiFront,
      ProductBack: ethiBack,
      Tag: "single",
    },
    {
      id: 4,
      Category: "Blends",
      Country: "Nicaragua & Ethiopia",
      RoastType: "Espresso Blend",
      CoffeeTaste: "",
      Price: "18",
      ShopifyLink:
        "https://shop.elevacoffee.com/collections/espresso-blends/products/brooklyn?variant=48051058966721",
      ProductFront: brooFront,
      ProductBack: brooBack,
      Tag: "blends",
    },
    {
      id: 5,
      Category: "Blends",
      Country: "",
      RoastType: "Espresso Blend",
      CoffeeTaste: "",
      Price: "18",
      ShopifyLink:
        "https://shop.elevacoffee.com/collections/espresso-blends/products/crema?variant=48051011518657",
      ProductFront: cremFront,
      ProductBack: cremBack,
      Tag: "blends",
    },
    {
      id: 6,
      Category: "Special",
      Country: "",
      RoastType: "Dark Roast",
      CoffeeTaste: "Strong & Velvety",
      Price: "18",
      ShopifyLink:
        "https://shop.elevacoffee.com/collections/our-coffees/products/dark-roast",
      ProductFront: darkFront,
      ProductBack: darkBack,
      Tag: "special",
    },
    {
      id: 7,
      Category: "Special",
      Country: "DECAF",
      RoastType: "Medium Roast",
      CoffeeTaste: "",
      Price: "18",
      ShopifyLink:
        "https://shop.elevacoffee.com/collections/special-blends/products/decaf?variant=40907527939",
      ProductFront: coloFront,
      ProductBack: coloBack,
      Tag: "special",
    },
  ];

  // تصنيف المنتجات حسب الفئة
  const categories = {
    "Single Origin": data.filter((product) => product.Tag === "single"),
    Blends: data.filter((product) => product.Tag === "blends"),
    Special: data.filter((product) => product.Tag === "special"),
  };

  return (
    <Container maxWidth="xl">
      {Object.entries(categories).map(([categoryName, products]) => (
        <Box key={categoryName}>
          <Box sx={{ my: "0%" }}>
            <Typography
              fontWeight={600}
              sx={{
                textTransform: "uppercase",
                fontSize: "40px",
                color: "#dcb246",
              }}
            >
              {categoryName}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            flexWrap="wrap"
            gap={3}
            sx={{ transform: "scale(0.8)", mt: {md:0, xs: "-20%" } }}
          >
            {products.length > 0 ? (
              products.map((product) => (
                <Box key={product.id}>
                  <ProductCard
                    Category={product.Category}
                    Country={product.Country}
                    RoastType={product.RoastType}
                    CoffeeTaste={product.CoffeeTaste}
                    Price={product.Price}
                    ShopifyLink={product.ShopifyLink}
                    ProductFront={product.ProductFront}
                    ProductBack={product.ProductBack}
                    Tag={product.Tag}
                    setCurrentId={setCurrentId}
                  />
                </Box>
              ))
            ) : (
              <ProductCardSkelton />
            )}
          </Box>
        </Box>
      ))}
    </Container>
  );
};

export default StoreSection;
