import React from "react";
import { Section1ST, Section2ND, Section3RD, Section4TH } from "./components";
import SEO from "../../hooks/Seo";

const OurCoffee = () => {
  return (
    <>
      <SEO
        title="Our Coffee | Elevated Coffee Experince"
        description="eleva coffee selection"
        name="our-coffee"
        type="our-coffee-selection"
      />
      {/* -------------  1st Section ------------- */}
      <Section1ST />
      {/* -------------  2nd Section ------------- */}
      <Section2ND />
      {/* -------------  3rd Section ------------- */}
      <Section3RD />
      {/* -------------  4th Section ------------- */}
      <Section4TH />
      {/*******************************************/}
    </>
  );
};

export default OurCoffee;
