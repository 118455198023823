import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { NavLink } from "react-router-dom";

import PlaceIcon from "@mui/icons-material/Place";

const ProductCard = ({
  Category,
  Country,
  RoastType,
  Price,
  ShopifyLink,
  ProductFront,
  ProductBack,
  CoffeeTaste,
}) => {
  const [flip, setFlip] = useState(false);

  const handleEnter = (e) => {
    setFlip(true);
  };
  const handleLeave = (e) => {
    setFlip(!flip);
  };
  return (
    <>
      <Box
        onMouseOver={() => {
          handleEnter();
        }}
        onMouseLeave={handleLeave}
      >
        {flip ? (
          <>
            <Box sx={{  }}>
              <Box
                component={"img"}
                src={ProductBack}
                alt=""
                width={"100%"}
                height={"100%"}
              />
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box
                component={"img"}
                src={ProductFront}
                alt=""
                width={"100%"}
                sx={{ transform: "scale(0.9) " }}
              />
            </Box>
          </>
        )}
        <Box
          mx={2}
          mb={1}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Box>
            <Typography variant="h4" sx={{ textAlign: "left" }}>
              {Category}
            </Typography>
          </Box>
          <Box display={"none"}>
            <Typography sx={{ fontSize: "2rem", textAlign: "left" }}>
              {Price} $
            </Typography>
          </Box>
        </Box>
        <Box
          mx={2}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                textAlign: "left",
              }}
            >
              <PlaceIcon
                variant="h5"
                sx={{ color: "goldenrod", display: "none" }}
              />{" "}
              {Country}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                textAlign: "left",
              }}
            >
              {RoastType}
            </Typography>
          </Box>
          <Box>
            <NavLink to={ShopifyLink}>
              <Button variant="contained" sx={{ backgroundColor: "#dcb246" }}>
                {" "}
                <Typography
                  variant="h5"
                  sx={{
                    color: "#fff",
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Buy Now
                </Typography>
              </Button>
            </NavLink>
          </Box>
        </Box>
        <Box
          sx={{
            ml: 2,
            mr: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Box>
            {" "}
            <Typography
              variant="h5"
              sx={{
                textAlign: "left",
              }}
            >
              {CoffeeTaste}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductCard;
