import React, { useEffect, useState } from "react";
//import { WholeSaleDisc } from "./components";
import { Box, Button, Grid, Typography } from "@mui/material";
import WholeSaleForm from "../../components/Shared/Form/WholeSaleForm/WholeSaleForm";
import { useDispatch } from "react-redux";
import { getOrders } from "../../actions/wholesale";
import logo from "../../assets/logo-coffee.png";
import sepCurve from "../../assets/wholesale/separated_curve.png";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import bean from "../../assets/wholesale/bean.png";
import hand from "../../assets/wholesale/hand.png";
import exp from "../../assets/wholesale/experincethedifference.png";
import custom from "../../assets/wholesale/customers-love.png";

import curve from "../../assets/wholesale/curve.png";
import PopupDialog from "../../components/Shared/PopupDialog/PopupDialog";

const firstHalf = [
  {
    id: 1,
    title: exp,
    items: [
      "Expertly Crafted Blends",
      "Owner Operated",
      "Locally Roasted",
      "Ethically sourced from  small farming communities",
      "Personalized Service",
      "Exceptional Value",
    ],
  },
];
const lastHalf = [
  {
    id: 2,
    title: custom,
    items: [
      "Weekly delivery services to NYC locations",
      "Barista training and equipment maintenance support from certified and vetted technicians",
      "Transparent pricing model - You'll know exactly what you're paying for",
    ],
  },
];

const Wholesale = () => {
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);

  useEffect(() => {
    dispatch(getOrders());
  }, [currentId, dispatch]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleHover = (e) => {
    e.preventDefault();
    setHover(true);
  };
  const handleUnhover = (e) => {
    e.preventDefault();
    setHover(false);
  };

  return (
    <>
      <>
        <Box
          sx={{
            paddingTop: "10%",
          }}
        >
          <Box
            zIndex={1}
            display={"flex"}
            justifyContent={"center"}
            sx={{
              paddingLeft: {
                xl: "10%",
                lg: "10%",
                md: "10%",
                sm: "20%",
                xs: "20%",
              },
              gap: { xl: 10, lg: 10, md: 0, sm: 0, xs: 0 },
            }}
          >
            <Box
              component={"img"}
              src={logo}
              alt=""
              sx={{
                width: {
                  xl: "20%",
                  lg: "20%",
                  md: "45%",
                  sm: "45%",
                  xs: "45%",
                },
                height: "20%",
              }}
            />

            <Box
              component={"img"}
              src={curve}
              alt=""
              sx={{
                width: {
                  xl: "20%",
                  lg: "20%",
                  md: "40%",
                  sm: "40%",
                  xs: "40%",
                },
                marginLeft: {
                  xl: "-15%",
                  lg: "-15%",
                  md: "-20%",
                  sm: "-20%",
                  xs: "-20%",
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Gallient",
                color: "#dec87c",
                paddingTop: "20px",
                fontSize: {
                  xl: "2.5rem",
                  lg: "2.5rem",
                  md: "2.5rem",
                  sm: "2.5rem",
                  xs: "2.5rem",
                },
              }}
            >
              Join the Eleva Team
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xl: "2.5rem",
                  lg: "2.5rem",
                  md: "2.5rem",
                  sm: "2.5rem",
                  xs: "1.6rem",
                },
                fontFamily: "Gallient",
                color: "#dec87c",
              }}
            >
              Become a Wholesale Partner !
            </Typography>
            <Box
              sx={{
                paddingTop: { xl: "", lg: "", md: "", sm: "", xs: "20px" },
              }}
            >
              <Button
                onMouseEnter={handleHover}
                onMouseLeave={handleUnhover}
                onClick={handleClickOpen}
                variant="contained"
                sx={{
                  backgroundColor: "#edd085",
                  width: {
                    xl: "200px",
                    lg: "200px",
                    md: "200px",
                    sm: "150px",
                    xs: "150px",
                  },
                  height: {
                    xl: "50px",
                    lg: "50px",
                    md: "50px",
                    sm: "40px",
                    xs: "40px",
                  },
                  borderRadius: "50px",
                  ":hover": {
                    backgroundColor: "goldenrod",
                  },
                }}
                size="large"
              >
                <Box padding={1}>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "20px",
                        lg: "18px",
                        md: "16px",
                        sm: "16px",
                        xs: "16px",
                      },
                      textTransform: "capitalize",
                      color: hover ? "#fff" : "#000",
                    }}
                  >
                    Join Now{" "}
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            flexWrap={"wrap"}
            sx={{
              paddingTop: {
                xl: "0%",
                lg: "0%",
                md: "20%",
                sm: "20%",
                xs: "20%",
              },
            }}
          >
            <Grid item xl={4} lg={4}>
              <Box
                sx={{
                  paddingLeft: {
                    xl: "15%",
                    lg: "15%",
                    md: "0",
                    sm: "0",
                    xs: "0%",
                  },
                  paddingRight: {
                    xl: "15%",
                    lg: "0%",
                    md: "20%",
                    sm: "20%",
                    xs: "20%",
                  },
                }}
              >
                {" "}
                {firstHalf.map((item) => (
                  <>
                    <Box sx={{ paddingTop: "0%", paddingBottom: "10%" }}>
                      <Box
                        component={"img"}
                        src={item.title}
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "110%",
                            md: "100%",
                            sm: "100%",
                            xs: "100%",
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          textAlign: "left",
                          paddingY: "5px",
                          paddingLeft: "10px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {item.items.map((point) => (
                          <Box
                            key={point.item}
                            flexWrap={"wrap"}
                            display={"flex"}
                          >
                            <Box paddingTop={"10px"}></Box>
                            <Typography
                              sx={{
                                fontSize: {
                                  xl: "1.8rem",
                                  lg: "1.8rem",
                                  md: "1.8rem",
                                  sm: "1.8rem",
                                  xs: "1.3rem",
                                },
                                fontFamily: "inherit",
                                color: "#dec87c",
                                whiteSpace: "discard-before",
                                paddingLeft: "15px",
                              }}
                            >
                              <Box display={"flex"} gap={1}>
                                <Box paddingTop={"2px"}>
                                  <ArrowCircleUpIcon
                                    sx={{
                                      rotate: "90deg",
                                      color: "#dec87c",
                                      fontSize: "30px",
                                    }}
                                  />
                                </Box>
                                <Box>{point}</Box>
                              </Box>
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </>
                ))}
              </Box>
            </Grid>

            <Grid item xl={4} lg={4}>
              <Box
                component={"img"}
                src={sepCurve}
                alt=""
                sx={{
                  zIndex: 0,
                  width: {
                    xl: "160%",
                    lg: "160%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  marginTop: {
                    xl: "0%",
                    lg: "0%",
                    md: "-100%",
                    sm: "-100%",
                    xs: "-100%",
                  },
                  marginLeft: {
                    xl: "-110%",
                    lg: "-110%",
                    md: "10%",
                    sm: "2%",
                    xs: "2%",
                  },

                  rotate: {
                    xl: "0deg",
                    lg: "10deg",
                    md: "0deg",
                    sm: "0deg",
                    xs: "0deg",
                  },
                }}
              />
            </Grid>
            <Grid item xl={4} lg={4}>
              <Box>
                <Box>
                  <Box
                    component={"img"}
                    src={bean}
                    sx={{ width: "35%", rotate: "60deg" }}
                  />
                </Box>
                <Box
                  sx={{
                    paddingTop: {
                      xl: "20%",
                      lg: "20%",
                      md: "",
                      sm: "",
                      xs: "",
                    },
                    marginRight: {
                      xl: "-10%",
                      lg: "-10%",
                      md: "0",
                      sm: "",
                      xs: "",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    src={hand}
                    sx={{
                      width: { xl: "55%", lg: "55%", md: "", sm: "", xs: "" },
                      rotate: "45deg",
                      marginLeft: "30%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginLeft: {
                      xl: "-50%",
                      lg: "-60%",
                      md: "",
                      sm: "",
                      xs: "",
                    },
                    paddingRight: {
                      xl: "40%",
                      lg: "40%",
                      md: "8%",
                      sm: "8%",
                      xs: "8%",
                    },
                  }}
                >
                  {" "}
                  {lastHalf.map((item) => (
                    <>
                      <Box sx={{ paddingTop: "0%", paddingBottom: "10%" }}>
                        <Box
                          component={"img"}
                          src={item.title}
                          sx={{ width: "100%" }}
                        />
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            textAlign: "left",
                            paddingY: "5px",
                            paddingLeft: "10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          {item.items.map((point) => (
                            <Box
                              key={point.item}
                              flexWrap={"wrap"}
                              display={"flex"}
                            >
                              <Box paddingTop={"10px"}></Box>
                              <Typography
                                sx={{
                                  fontSize: {
                                    xl: "1.8rem",
                                    lg: "1.8rem",
                                    md: "2.5rem",
                                    sm: "2.5rem",
                                    xs: "1.3rem",
                                  },
                                  fontFamily: "inherit",
                                  color: "#dec87c",
                                  whiteSpace: "discard-before",
                                  paddingLeft: "15px",
                                }}
                              >
                                <Box display={"flex"} gap={1}>
                                  <Box paddingTop={"2px"}>
                                    <ArrowCircleUpIcon
                                      sx={{
                                        rotate: "90deg",
                                        color: "#dec87c",
                                        fontSize: "30px",
                                      }}
                                    />
                                  </Box>
                                  <Box>{point}</Box>
                                </Box>
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </>
                  ))}
                </Box>{" "}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/*
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            sx={{
              marginTop: {
                xl: "0%",
                lg: "10%",
                md: "15%",
                sm: "25%",
                xs: "30%",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xl: "50%",
                  lg: "50%",
                  md: "50%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            >
              <WholeSaleDisc />
            </Box>
            <Box
              sx={{
                width: {
                  xl: "50%",
                  lg: "50%",
                  md: "50%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            >
              <WholeSaleForm
                currentId={currentId}
                setCurrentId={setCurrentId}
              />
            </Box>
          </Box>

*/}{" "}
        <PopupDialog
          open={open}
          onClose={handleClose}
          form={
            <WholeSaleForm currentId={currentId} setCurrentId={setCurrentId} />
          }
        />
      </>
    </>
  );
};

export default Wholesale;
