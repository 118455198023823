import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import StoreSection from "../../components/Shared/StoreSection/StoreSection";
import outline from "../../assets/Shop/products_imgs/mountain_outline.png";
import cold from "../../assets/Shop/products_imgs/cold_coffee_cup.png";
import cup from "../../assets/Shop/products_imgs/empty_coffee_cup.png";
import hand from "../../assets/Shop/products_imgs/han_top-view.png";
import { useDispatch } from "react-redux";
import { getProducts } from "../../actions/products";

const Store = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
  }, [currentId, dispatch]);

  return (
    <>
      <Box
        sx={{
          marginTop: { xl: "10%", lg: "10%", md: "15%", sm: "25%", xs: "35%" },
        }}
      >
        <Box >
          <StoreSection origin setCurrentId={setCurrentId} />
        </Box>
      </Box>
      <Box
        justifyContent="space-between"
        marginBottom={-20}
        sx={{
          marginBottom: { xl: -20, lg: -20, md: 0, sm: 0, xs: 0 },
          display: {
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "none",
            xs: "none",
          },
        }}
        marginTop={20}
        flexWrap={"wrap"}
      >
        <Box
          marginLeft={40}
          sx={{ marginLeft: { xl: 40, lg: 0, md: 40, sm: 0, xs: 0 } }}
        >
          <Box component={"img"} src={cold} />
        </Box>
        <Box>
          <Box component={"img"} src={cup} />
        </Box>
        <Box>
          <Box component={"img"} src={hand} />
        </Box>
      </Box>
      <Box component={"img"} src={outline} width={"100%"} />
    </>
  );
};

export default Store;
