import { Box } from "@mui/material";
import React from "react";
import nicaragua from "../../../../assets/nicaragua.png";
import map from "../../../../assets/map.png";
import map_2 from "../../../../assets/g.png";
import map_3 from "../../../../assets/map_3.png";
import guatemala from "../../../../assets/guatemala.png";
import ethiopia from "../../../../assets/ethiopia.png";
import { useStyles } from "./Style";
import Discover from "../../../../components/Shared/Discoverbtn/Discover";

const Section2ND = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.OurCoffee_Nicaragua}>
        <Box className={classes.nicaragua} component={"img"} src={nicaragua} />
        <Box component={"img"} src={map} className={classes.map} />
        <Discover
          link="https://shop.elevacoffee.com/collections/single-origin/products/nicaragua?variant=40908931139"
          title="Discover Nicaragua "
        />
      </Box>

      <Box className={classes.OurCoffee_Guatemala} sx={{ marginTop: "5%" }}>
        <Box component={"img"} src={map_2} className={classes.map_2} />
        <Box className={classes.guatemala} component={"img"} src={guatemala} />
        <Box sx={{ paddingTop: "50px" }}>
          <Discover
            link="https://shop.elevacoffee.com/collections/our-coffees/products/guatemala?variant=40908888963"
            title="Discover Guatemala "
          />
        </Box>
      </Box>

      <Box className={classes.OurCoffee_Ethiopia} sx={{ marginTop: "5%" }}>
        <Box className={classes.ethiopia} component={"img"} src={map_3} />
        <Box component={"img"} src={ethiopia} className={classes.map_3} />
        <Discover
          link="https://shop.elevacoffee.com/collections/single-origin/products/ethiopia?variant=5806994587677"
          title="Discover Ethiopia"
        />
      </Box>
    </>
  );
};

export default Section2ND;
