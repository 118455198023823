import React from "react";
import { Box } from "@mui/material";
import Menu from "./Menu";
import Logo from "./Logo";
import RHeader from "./Responsive/RHeader";
//import "./style.css";
import { useStyles } from "./Style";

const Header = () => {
  const classes = useStyles();
  return (
    <>
      <Box>
        <Box className={classes.RHeader}>
          <RHeader />
        </Box>
        <Box
          component={"header"}
          sx={{
            display: {
              xl: "flex",
              lg: "flex",
              md: "none",
              sm: "none",
              xs: "none",
            },
            position: "fixed",
            justifyContent: "space-between",
            zIndex: 5,
            alignContent: "center",
            alignItems: "center",
            width: "90%",
          }}
        >
          {/* LOGO  */}
          <Box sx={{ marginLeft: "10%" }}>
            <Logo />
          </Box>
          {/* SEARCH BAR*/}
          {/*<SearchBar />*/}
          {/* MENU BAR  \*/}
          <Box sx={{}}>
            <Menu />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
